/*****************************************
                FONTS
*****************************************/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');



/****************************************
              GENERAL
****************************************/
html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
  color:#292C2E;
  max-width: 1300px;
  margin: auto;
  box-sizing: border-box;
}

a, a:hover, a:focus {
  color:#292C2E;
  text-decoration: none;
}

/***************************************
                HEADER
***************************************/
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 15px 0 35px;
  position:sticky;
  top:0;
  background-color: #fff;
  z-index: 999;
}

header nav a {
  display: inline-block;
  padding: 12px 32px;
  /* margin-right: 30px; */
  font-size: 1.6rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

header img {
  max-width: 300px;
}

/*************************************
                FORM
*************************************/
.formio-form {
  max-width: 70%;
  margin: 20px auto;
}
nav#wizard-e6gswvb-header {
  margin-bottom: 40px;
}
/* ul.pagination {
  display: flex;
  justify-content: space-around;
} */
.pagination>li>span {
  margin-right: 40px;
  border-radius: 5px;
  font-weight: bold;
  color: #FFC003;
}
.pagination>li>span:hover {
  color: #c79000;
}

.pagination>.active>span {
  background-color: #FFC003;
  border-color: #FFC003;
}
.pagination>.active>span:hover {
  background-color: #FFC003;
  border-color: #FFC003;
}

.wizard-page {
  box-shadow: 0px 4px 8px rgba(0,0,0,0.2);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
}

a.browse {
  color: #c79000;
}
.list-inline {
  margin-top: 30px;
  text-align: right;
}
button.btn.btn-secondary.btn-wizard-nav-cancel {
  color: #c79000;
  font-weight: bold;
}

button.btn.btn-primary.btn-wizard-nav-next, button.btn.btn-primary.btn-wizard-nav-previous, .formio-button-add-row, button.btn.btn-primary.btn-wizard-nav-submit {
  background-color: #FFC003;
  border-color: #FFC003;
  font-weight: bold;
}

button.btn.btn-primary.btn-wizard-nav-next:hover, button.btn.btn-primary.btn-wizard-nav-previous:hover, .formio-button-add-row:hover, button.btn.btn-primary.btn-wizard-nav-submit:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:active:hover, .btn-primary:active:focus {
  background-color: #c79000;
  border-color: #c79000;
}
.form-group.has-feedback.formio-component.formio-component-datasource {
  display: none;
}

/**************************************
                MAIN
**************************************/
section {
  display: flex;
  align-items: center;
  max-width: 1280px;
  margin: 80px auto;
}
h1, h2 {
  font-size: 4.6rem;
  font-weight: 900;
  padding: 24px 0;
}

h2 {
  font-size: 3.2rem;
  padding-right: 4rem;
}

p {
  font-size: 2rem;
  font-weight: 600;
  padding: 12px 0;
}


/******PERSONNALISE*****/

.personnalise img {
  max-width: 900px;
}
.personnalise a {
  display: inline-block;
  padding: 16px 32px;
  border: 2px solid #292C2E;
  border-radius: 3rem;
  font-weight: 600;
}

/******CHALLENGES*****/

.challenges img {
  max-width: 444px;
  margin: 24px 94px;

}

.challenges p {
  padding-right: 4rem;
}


